import {  GET_INVENTORY_SELECT_LIST,
    FETCH_INVENTORY_SELECT_LIST, CLEAR_ALL } 
    from "constants/actions";

  const initialState = {
    inventories: [],
    selected: []
  };
  
  export default function IventorySelect (state = initialState, action) {
    switch (action.type) {
      case GET_INVENTORY_SELECT_LIST:
        return {
          ...state,
          inventories: action.payload,
        };
      case FETCH_INVENTORY_SELECT_LIST:
        return { ...state, selected: action.payload };
  
      
   
      case CLEAR_ALL:
        return {
          ...state,
          inventories: [],
          selected: []
        };
      default:
        return state;
    }
  }