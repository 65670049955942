import { ADD_APPOINTMENT, CLEAR_ALL } from "constants/actions";

const initialState = {
    appointment: [],
    created: []
};

export default function Appointment (state = initialState, action) {
    switch (action.type) {

        case ADD_APPOINTMENT:
            return {
                ...state,
                created: action.payload
            };
       
        case CLEAR_ALL:
            return {
                ...state,
                appointment: []
            };
        default:
            return state;
    }
}

