import { GET_CARBODYTYPES, DELETE_CARBODYTYPE, 
    ADD_CARBODYTYPE, FETCH_CARBODYTYPE, EDIT_CARBODYTYPE, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    carbodytypes: []
};

export default function CarBodyType (state = initialState, action) {
    switch (action.type) {
        case GET_CARBODYTYPES:
            return {
                ...state,
                carbodytypes: action.payload
            };
        case FETCH_CARBODYTYPE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARBODYTYPE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARBODYTYPE:
            return {
                ...state,
                carbodytypes: state.carbodytypes.filter(Car => Car.id !== action.payload)
            };
        case ADD_CARBODYTYPE:
            return {
                ...state,
                carbodytypes: [...state.carbodytypes, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                carbodytypes: []
            };
        default:
            return state;
    }
}

