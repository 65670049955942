import { GET_CARCOLORS, DELETE_CARCOLOR, ADD_CARCOLOR, 
    FETCH_CARCOLOR, EDIT_CARCOLOR, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    carcolors: []
};

export default function CarColors (state = initialState, action) {
    switch (action.type) {
        case GET_CARCOLORS:
            return {
                ...state,
                carcolors: action.payload
            };
        case FETCH_CARCOLOR:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARCOLOR:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARCOLOR:
            return {
                ...state,
                carcolors: state.carcolors.filter(Car => Car.id !== action.payload)
            };
        case ADD_CARCOLOR:
            return {
                ...state,
                carcolors: [...state.carcolors, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                carcolors: []
            };
        default:
            return state;
    }
}

