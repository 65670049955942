import React from "react";
import { Helmet } from "react-helmet";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

import "./style.css";

import promo_png_left from "assets/images/warranty/bryden-warranty-providers-coverage-one.jpg";
import promo_png_right from "assets/images/warranty/bryden-warranty-providers-global.jpg";

function Warranty() {
  document.documentElement.classList.remove("nav-open");
  // backgroundColor:"#003468"
  return (
    <>
      <Helmet>
          <title>
            Warranty information for vehicles Dave Forde Financing
          </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Warranty information"/>  
      </Helmet>
      <hr />
      <Container className="mt-3" fluid>
        
      </Container>

      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <h1 className="blue-title">
                Warranty Information
            </h1>
          </Col>
        </Row>

        <Row>
          <Col className="ml-auto mr-auto text-left" md="12">
            <h2 className="red-sub-title mb-3"> Protect Your Vehicle For Years to Come with an Extended Warranty</h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            <p className="description">
              Protect your vehicle against expensive repairs that might come-up
              during your course of owning your vehicle. We offer extended
              warranty on all vehicles we carry in stock. Please ask our sales
              representative about possible warranty options available for your
              vehicle.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
          <Row className="mt-2 no-gutters about-wrap">
            <Col md="6" sm="12">
              <img
                className="img-fluid w-100"
                src={promo_png_left}
                alt="lease buster"
              />
            </Col>
            <Col md="6" sm="12">
              <img
                className="img-fluid w-100"
                src={promo_png_right}
                alt="lease buster"
              />
            </Col>
          </Row>
        </Container>
    </>
  );
}

export default Warranty;
