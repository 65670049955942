import { ADD_WEBLEAD, GET_WEB_LEAD_SOURCES, CLEAR_ALL } from "constants/actions";

const initialState = {
    webleads: [],
    webleadsource: [],
};

export default function WebLeads (state = initialState, action) {
    switch (action.type) {
        case GET_WEB_LEAD_SOURCES:
            return {
                ...state,
                webleadsource: action.payload
            };

        case ADD_WEBLEAD:
            return {
                ...state,
                weblead: [...state.webleads, action.payload]
            };
       
        case CLEAR_ALL:
            return {
                ...state,
                webleads: []
            };
        default:
            return state;
    }
}

