import React from 'react';
import Styled from "styled-components"

import { Container } from 'react-bootstrap';

import Footer from 'layout/HomeLayout/Footer';
import NavBar from 'layout/HomeLayout/NavBar';

export const LoaderCircle = () => {
    return (
        <Container>
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>

        </Container>

    );
};

export default LoaderCircle;


export const LoaderInPage = () => {
    return (
        <>
            <FlexContainer>
                <FlexRow>
                    <FlexItem>
                        <div className="spinner-border" role="status">
                        </div>
                    </FlexItem>
                    <CenterBox>
                        <span className="sr-only">Loading...</span>
                    </CenterBox>
                </FlexRow>
            </FlexContainer>
        </>
    );
};


export const FullPageLoader = () => {
    return (
        <>
            <NavBar />
            <FlexContainer>
                <FlexRow>
                    <FlexItem>
                        <div className="spinner-border" role="status">
                        </div>
                    </FlexItem>
                    <CenterBox>
                        <span className="sr-only">Loading...</span>
                    </CenterBox>
                </FlexRow>
            </FlexContainer>
            <Footer />
        </>
    );
};


const CenterBox = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const FlexContainer = Styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FlexRow = Styled.div`
    width: auto;
`


const FlexItem = Styled.div`
    background-color: tomato;
    padding: 5px;
    width: 60px;
    height: 40px;
    margin: 10px;
    line-height: 20px;
    color: white;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
`