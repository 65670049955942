import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// reactstrap components
import { Button, Row, Col } from "reactstrap";

import "InventoryList/list.css";
import { SG_SELECT_INVENTORY, SG_APPLIED_INVENTORY } from "constants/actions";

const ProductCard = ({ inventorySelected, setInSection, closeApplication }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  function GoBack() {
    dispatch({ type: SG_SELECT_INVENTORY, payload: false });
    navigate(`/inventory`);
  }

  function Apply() {
    dispatch({ type: SG_APPLIED_INVENTORY, payload: inventorySelected });
    if (setInSection) {
      setInSection(3);
    } else {
      navigate(`/application`);
    }
  }
  console.log(inventorySelected);
  return (
    <>
      <LargeContainer>
        <Button close onClick={closeApplication} />
        <Row>
          <Col md="8" sm="12" lg="8">
            <div>
              {/* <LazyLoad height={220}> */}

              <img
                alt={`${inventorySelected.year} ${inventorySelected.make}`}
                className="img-fluid"
                src={inventorySelected.image}
              />
              {inventorySelected.status === 6 ? (
                <div className="vehicle-overlay-white">SOLD</div>
              ) : (
                ""
              )}

              {/* </LazyLoad> */}
            </div>
          </Col>
          <Col md="4" sm="12" lg="4">
            <RightLayout>
              <DescriptioContainer>
                <YearMake>
                  {inventorySelected.year} {inventorySelected.make}
                </YearMake>
                <ModelTrim>{inventorySelected.model}</ModelTrim>

                <Trim>{inventorySelected.trim}</Trim>
                <Kilometers>
                  Mileage: {inventorySelected.kilometers} km
                </Kilometers>

                <ListPrice>
                  {inventorySelected.list_price !== "0.00" &&
                  inventorySelected.list_price
                    ? `${new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(inventorySelected.list_price)}`
                    : ""}
                </ListPrice>

                {/* <small>{inventorySelected.trim}</small> */}
              </DescriptioContainer>
              <MiddleButton>
                <Button color="danger" onClick={Apply}>
                  Start Application
                </Button>
              </MiddleButton>
            </RightLayout>
          </Col>
        </Row>
      </LargeContainer>
    </>
  );
};

export default ProductCard;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;
const RightLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DescriptioContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  z-index: 9;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 100px;
  // background-color: #dcdcdc;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
`;

const MiddleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  min-height: 100px;
  // background-color: #dcdcdc;
`;

const ListPrice = styled.div`
  padding: 15px 0 15px 0;
  font-size: 200%;
  font-weight: 600;
  color: #00700b;
  height: 17px;
`;

const Kilometers = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 120%;
  font-weight: 500;
  color: #54595f;
  height: 15px;
`;

const YearMake = styled.div`
  font-size: 100%;
  font-weight: 500;
  color: #0046b8;
`;

const ModelTrim = styled.div`
  font-size: 250%;
  font-weight: 600;
  color: #08234f;
`;

const Trim = styled.div`
  font-size: 120%;
  font-weight: 600;
  color: #042d6e;
`;
