import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { useHistory, useLocation } from 'react-router-dom'
// import InputRange from "react-input-range";
import {
  Card,
  // CardText,
  CardBody,
  // CardFooter,
  Row,
  Col,
  Input,
} from "reactstrap";

import { useSelector, shallowEqual } from "react-redux";

// import {
//   CALCULATOR_DEFAULTS,
//   UPDATE_CALCULATOR,
//   CALCULATOR_RESULTS,
//   CLEAR_CALCULATOR,
//   CLEAR_ALL
// } from "constants/actions";

// import SingleSlider from "RangeSlider/Single"
// import Slider, { createSliderWithTooltip } from "rc-slider";
// import "rc-slider/assets/index.css";
import "./style.css";
// import CustomSlider from "./Slider";

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

import {
  CarCardsSimilarPriced,
} from "InventoryList/CarCardsSimilar"


import Calculate from "Calculator/Calculate"

import config from "_config";
export function Calculator() {
  // const dispatch = useDispatch();
  const { get_calculator } = useSelector(
    (state) => ({
      get_calculator: state.calculator,
    }),
    shallowEqual
  );

  const [amountValue, setAmountValue] = useState(get_calculator.amountValue);
  const [financeValue, setfinanceValue] = useState(0);
  const [yearsValue, setYearsValue] = useState(get_calculator.yearsValue);
  const [interestValue, setInterestValue] = useState(get_calculator.interestValue);
  const [tradeinValue, settradeinValue] = useState(get_calculator.tradeinValue);
  const [downPayment, setdownPayment] = useState(get_calculator.downPayment);

  // const [salesTaxPercent, setSalesTaxPercent] = useState(get_calculator.interestValue);
  // const [salesTaxValue, setSalesTaxValue] = useState(get_calculator.interestValue);
  // const [otherLeviesValue, setOtherLeviesValue] = useState(get_calculator.interestValue);
  
  useEffect(() => {
    // console.log(get_calculator)
    setInterestValue(get_calculator.interestValue)
    setAmountValue(get_calculator.amountValue)
    settradeinValue(get_calculator.tradeinValue)
    setdownPayment(get_calculator.downPayment)
    setYearsValue(get_calculator.yearsValue)

    // setSalesTaxPercent(get_calculator.salesTaxPercent)
    // setSalesTaxValue(get_calculator.salesTaxValue)
    // setOtherLeviesValue(get_calculator.otherLevies)

  }, [get_calculator])

  //   calculator: {
  //     amountValue: 23000,
  //     downPayment: 0,
  //     interestValue: 4.99,
  //     tradeinValue: 2500,
  //     yearsValue: 5
  // }

  let years = yearsValue;
  let interest = interestValue / 100;

  // let salesTax = salesTaxPercent / 100;

  // console.log(Number(amountValue[0])  -(Number(downPayment[0]) + Number(tradeinValue[0])))
  React.useEffect(() => {
    setfinanceValue(Number(amountValue) - (Number(downPayment) + Number(tradeinValue)))
  }, [amountValue, downPayment, tradeinValue])

  // console.log(amountValue)
  // console.log(interestValue)
function setInterestText(value) {
  // console.log(value)
  setInterestValue(value*100)
}

  return (
    <div>
      <Helmet>
        <title>
          Credit Experts Dave Forde Auto Sales | Everyone's Approved
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={`Credit Experts and Used cars in 
        ${config.city} get more deals by ${config.company}| Everyone's Approved`} />
      </Helmet>
      <Row>
        <Col className="text-left" md="12">
          <Card className="card-raised page-carousel">
            <CardBody>
              {" "}
              <div className="block-div">
                <p className="lead">

                  {get_calculator?.vehicle ? `${get_calculator?.vehicle} for CA$ ${get_calculator?.vehicleAmount}` : "Vehicle List Price"}</p>
                
                <Row>
                  <Col xs="12" sm="12" md="8" className="mt-3">
                    
                    <RangeSlider
                      value={amountValue}
                      onChange={e => setAmountValue(e.target.value)}
                      min={0}
                      max={100000}
                      step={250}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">
                  
                    <Input
                      // type="number"
                      className="text-right"
                      // defaultValue={23000}
                      // disabled
                      value={amountValue}
                      onChange={(e) => setAmountValue(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">(minus) Trade Value</p>

                <Row>
                  <Col xs="12" sm="12" md="8" className="mt-3">
                   
                    <RangeSlider
                      value={tradeinValue}
                      onChange={e => settradeinValue(e.target.value)}
                      min={0}
                      max={50000}
                      step={250}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">

                    <Input
                      type="number"
                      className="text-right"
                      value={tradeinValue}
                      // disabled
                      onChange={e => settradeinValue(e.target.value)}
                      tooltips={"tooltips"}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">(minus) Down Payment</p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                    
                    <RangeSlider
                      value={downPayment}
                      onChange={e => setdownPayment(e.target.value)}
                      min={0}
                      max={20000}
                      step={100}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="4" className="space-on-small">
                    <Input
                      type="number"
                      className="text-right"
                      // disabled
                      value={downPayment}
                      onChange={e => setdownPayment(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead">
                  Over {Math.round(yearsValue)} year{years > 1 && "s"}
                </p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                    
                    <RangeSlider
                      value={Math.round(yearsValue)}
                      onChange={e => setYearsValue(e.target.value)}
                      min={1}
                      max={8}
                      step={1}
                    />
                  </Col>
                  <Col xs="12" md="4">
                    <Input
                      type="number"
                      className="text-right"
                      // disabled
                      value={yearsValue}
                      onChange={e => setYearsValue(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="block-div">
                <p className="lead"> {interest.toFixed(2)}% Interest</p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                   
                    <RangeSlider
                      value={Math.round(interestValue).toFixed(2)}
                      onChange={e => setInterestValue(e.target.value)}
                      min={199}
                      max={5000}
                      tooltip='off'
                    />
                  </Col>
                  <Col xs="12" md="4">
                  <Input
                      type="number"
                      className="text-right"
                      // disabled
                      value={Math.round(interestValue).toFixed(2)/100}
                      onChange={e => setInterestText(e.target.value)}
                    />

                  </Col>
                </Row>
              </div>
              {/* <div className="block-div">
                <p className="lead"> {salesTax.toFixed(2)}% Sales Tax</p>

                <Row>
                  <Col xs="12" md="8" className="mt-3">
                   
                    <RangeSlider
                      value={Math.round(salesTaxValue).toFixed(2)}
                      onChange={e => setSalesTaxValue(e.target.value)}
                      min={199}
                      max={5000}
                      tooltip='off'
                    />
                  </Col>
                  <Col xs="12" md="4">
                  <Input
                      type="number"
                      className="text-right"
                      // disabled
                      value={Math.round(salesTaxValue).toFixed(2)/100}
                      onChange={e => setSalesTaxValue(e.target.value)}
                    />

                  </Col>
                </Row>
              </div> */}
              <div className="block-div">
                <Row>
                  <Col xs="12" md="7">
                    <h3 className="lead-total">Total Finance</h3>
                  </Col>
                  <Col
                    xs="12"
                    md="5"
                    className="total-value-highlight"
                  >
                    <h3 className="lead-total-number">
                      {new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(financeValue)}
                    </h3>

                  </Col>
                </Row>
              </div>
            </CardBody>
            <hr />

            <Calculate
              interestValue={interestValue}
              amountValue={amountValue}
              yearsValue={yearsValue}
              downPayment={downPayment}
              tradeinValue={tradeinValue}
            />
          </Card>
        </Col>
      </Row>

      <p className="lead">*Indicative values shown, taxes &amp; other levies not  included</p>

      {get_calculator?.vehicleAmount > 0 &&
        <CarCardsSimilarPriced
          id={get_calculator?.id}
          query={get_calculator?.vehicleAmount} />
      }
    </div>
  );
}

// export function LongCalculator() {
//   const search = window.location.search;
//   const params = new URLSearchParams(search);


//   const [amountValue, setAmountValue] = useState(23390);
//   const [financeValue, setfinanceValue] = useState(23390);

//   const [yearsValue, setYearsValue] = useState(5);
//   const [interestValue, setInterestValue] = useState(899);


//   const _theApr = params.get("interest")? params.get("interest") : 499;
//   if (_theApr) {
//       localStorage.setItem("theApr", _theApr);
//   }


//   const [tradeinValue, settradeinValue] = useState(0);
//   const [downPayment, setdownPayment] = useState(0);


//   const SliderWithTooltip = createSliderWithTooltip(Slider);

//   const radioforOutput2 = () => {
//     let currAPR = interestValue / 100 / 100;
//     // theApr = currAPR
//     const decimalFormat = currAPR + 1;
//     const totalOwed = decimalFormat * financeValue;
//     const monthlyRepayment = totalOwed / (yearsValue * 12);
//     const biWeeklyRepayment = totalOwed / (yearsValue * 26);
//     const weeklyRepayment = totalOwed / (yearsValue * 52);

//     return (
//       <Row>
//         <Col sm="4">
//           <Card body>
//             <CardText>Weekly</CardText>
//             <CardText>
//               <p className="value-highlight">${Math.round(weeklyRepayment)}</p>
//             </CardText>
//           </Card>
//         </Col>
//         <Col sm="4">
//           <Card body>
//             <CardText>Bi Weekly</CardText>
//             <CardText>
//               <p className="value-highlight">
//                 ${Math.round(biWeeklyRepayment)}
//               </p>
//             </CardText>
//           </Card>
//         </Col>
//         <Col sm="4">
//           <Card body>
//             <CardText>Monthly</CardText>
//             <CardText>
//               <p className="value-highlight">${Math.round(monthlyRepayment)}</p>
//             </CardText>
//           </Card>
//         </Col>
//       </Row>
//     );
//   };

//   useEffect(() => {
//     setfinanceValue(amountValue - (downPayment + tradeinValue));
//   }, [amountValue, tradeinValue, downPayment]);

//   const handleDownChange = async (value) => {
//     setdownPayment(value);
//     // handleFinanceValue()
//   };

//   const handleTradeChange = async (value) => {
//     // console.log("trade",value)
//     settradeinValue(value);
//     // console.log("trade",tradeinValue)
//     // setfinanceValue(amountValue - (downPayment + value))
//     // handleFinanceValue()
//   };

//   const handleAmountChange = async (value) => {
//     setAmountValue(value);
//     // handleFinanceValue()
//   };

//   const onDownChange = (e) => {
//     setdownPayment(+e.target.value);
//     // handleFinanceValue()
//   };

//   const onTradeChange = (e) => {
//     settradeinValue(+e.target.value);
//     // handleFinanceValue()
//   };
//   const onAmountTextChange = (e) => {

//     setAmountValue(e.target.value);
//     // handleFinanceValue()
//   };

//   const handleYearChange = (value) => {
//     setYearsValue(value);
//   };

//   const handleInterestChange = (value) => {
//     setInterestValue(value);
//   };

//   // let { amount, years, interest } = 0;
//   // console.log(amountValue)

//   let years = yearsValue;
//   let interest = interestValue / 100;
//   return (
//     <div>
//       <Row>
//         <Col className="text-center" md="12">
//           <Card className="card-contributions">
//             <CardBody>
//               <h5>
//                 {" "}
//                 <Row>
//                   <Col xs="7">Amount $</Col>
//                   <Col xs="5" className="text-right">
//                     <Input
//                       type="number"
//                       className="text-right"
//                       value={amountValue}
//                       onChange={onAmountTextChange}
//                     />
//                   </Col>
//                   <Col xs="12">
//                     <SliderWithTooltip
//                       value={amountValue}
//                       onChange={handleAmountChange}
//                       min={5000}
//                       max={90000}
//                       step={1000}
//                       railStyle={{ backgroundColor: "gray", height: 2 }}
//                       trackStyle={{ backgroundColor: "blue", height: 2 }}
//                     />
//                   </Col>
//                 </Row>
//               </h5>

//               <h5>
//                 {" "}
//                 <Row>
//                   <Col xs="7">- Trade $</Col>
//                   <Col xs="5" className="text-right">
//                     <Input
//                       type="number"
//                       className="text-right"
//                       value={tradeinValue}
//                       onChange={onTradeChange}
//                     />
//                   </Col>
//                   <Col xs="12">
//                     <SliderWithTooltip
//                       value={tradeinValue}
//                       onChange={handleTradeChange}
//                       min={1000}
//                       max={10000}
//                       step={250}
//                       railStyle={{ backgroundColor: "gray", height: 2 }}
//                       trackStyle={{ backgroundColor: "blue", height: 2 }}
//                     />
//                   </Col>
//                 </Row>
//               </h5>

//               <h5>
//                 {" "}
//                 <Row>
//                   <Col xs="7">- Down $</Col>
//                   <Col xs="5" className="text-right">
//                     <Input
//                       type="number"
//                       className="text-right"
//                       value={downPayment}
//                       onChange={onDownChange}
//                     />
//                   </Col>
//                   <Col xs="12">
//                     <SliderWithTooltip
//                       value={downPayment}
//                       onChange={handleDownChange}
//                       min={500}
//                       max={5000}
//                       step={100}
//                       railStyle={{ backgroundColor: "gray", height: 2 }}
//                       trackStyle={{ backgroundColor: "blue", height: 2 }}
//                     />
//                   </Col>
//                 </Row>
//               </h5>

//               <h5>
//                 <Row>
//                   <Col xs="7">Total Finance $</Col>
//                   <Col xs="5" className="text-right">
//                     {financeValue}{" "}
//                   </Col>
//                 </Row>
//               </h5>

//               <h5>
//                 Over {years} year{years > 1 && "s"}
//                 <SliderWithTooltip
//                   value={yearsValue}
//                   onChange={handleYearChange}
//                   min={1}
//                   max={8}
//                   railStyle={{ backgroundColor: "gray", height: 2 }}
//                   trackStyle={{ backgroundColor: "blue", height: 2 }}
//                 />
//               </h5>
//               <h5>
//                 {interest}% Interest
//                 <SliderWithTooltip
//                   value={interestValue}
//                   onChange={handleInterestChange}
//                   min={199}
//                   max={2500}
//                   railStyle={{ backgroundColor: "gray", height: 2 }}
//                   trackStyle={{ backgroundColor: "blue", height: 2 }}
//                 />
//               </h5>
//             </CardBody>

//             {/* {radioforOutput2()} */}

//             <small>Indicative values shown</small>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// }

export default Calculator;
