import React from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import {
  SwitchTransition,
  CSSTransition,
  TransitionGroup,
} from "react-transition-group";

import PromoHeader from "./PromoHeader";
import Budget from "./Budget";
import LenderImages from "./LenderImages";
import Steps from "./3Steps";

import LeadSource from "utilities/LeadSource";
import ContactInfo from "./Steps/ContactInfo";

import CreditCheck from "./Steps/CreditCheck";
import Residence from "./Steps/Residence";
import Employment from "./Steps/Employment";
// import TradeIn from "./Steps/TradeIn";
import Thanks from "./Steps/Thanks";
import ProductApplyWide from "./Steps/ProductApplyWide";

import Fade from "react-reveal/Fade";

import "./style.css";
// reactstrap components
import { Container, Row, Col, Button } from "react-bootstrap";
import AnnualIncome from "./Steps/AnnualIncome";
import OwnHouse from "./Steps/OwnHouse";

const Promotion = () => {
  const dispatch = useDispatch();
  document.documentElement.classList.remove("nav-open");
  const [inSection, setInSection] = React.useState(1);
  const [application, setApplication] = React.useState(false);
  const [selectedBudget, setSelectedBudget] = React.useState(false);
  const [direction, setDirection] = React.useState(true);
  const [inventorySelected, setInventorySelected] = React.useState(false);

  const _vehicle = 1;
  const _budget = 2;
  const _inventory = 2;
  const _contact = 3;
  const _employment = 4;
  const _residence = 5;
  const _credit = 6;
  // const _tradein = 7;
  const _thanks = 8;

  function closeApplication() {
    setInSection(1);
  }

  const PageList = {
    1: {
      name: _vehicle,
      no: 1,
      element: (
        <PromoHeader setInSection={setInSection} application={application} />
      ),
    },
    11: {
      name: _contact,
      no: 11,
      element: (
        <>
          <Steps />
          <Container>
            <LenderImages />
          </Container>
        </>
      ),
    },
    22: {
      name: _inventory,
      no: 22,
      element: (
        <ProductApplyWide
          inventorySelected={inventorySelected}
          setInSection={setInSection}
          closeApplication={closeApplication}
        />
      ),
    },
    2: {
      name: _budget,
      no: 2,
      element: (
        <Budget
          setInSection={setInSection}
          setDirection={setDirection}
          application={application}
          closeApplication={closeApplication}
        />
      ),
    },
    3: {
      name: _contact,
      no: 3,
      element: (
        <ContactInfo
          setInSection={setInSection}
          setDirection={setDirection}
          closeApplication={closeApplication}
        />
      ),
    },
    4: {
      name: _employment,
      no: 4,
      element: (
        <AnnualIncome
          setInSection={setInSection}
          setDirection={setDirection}
          closeApplication={closeApplication}
        />
      ),
    },
    5: {
      name: _residence,
      no: 5,
      element: (
        <OwnHouse
          setInSection={setInSection}
          setDirection={setDirection}
          closeApplication={closeApplication}
        />
      ),
    },
    6: {
      name: _credit,
      no: 6,
      element: (
        <CreditCheck
          setInSection={setInSection}
          setDirection={setDirection}
          closeApplication={closeApplication}
        />
      ),
    },
    7: {
      name: _thanks,
      no: 6,
      element: (
        <Thanks setInSection={setInSection} setDirection={setDirection} />
      ),
    },
  };

  const { get_application, get_applied } = useSelector(
    (state) => ({
      get_application: state.application.application,
      get_applied: state.inventories.applied,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setApplication(get_application);
  }, [get_application]);

  React.useEffect(() => {
    setInventorySelected(get_applied);
    if (get_applied?.id) {
      setInSection(3);
    }
  }, [get_applied]);

  // console.log(get_application);
  // console.log(get_applied);
  console.log(inSection);

  function NextStep() {
    if (inSection < 7) {
      setInSection(inSection + 1);
    }
  }

  return (
    <>
      <div className="container">
        {inSection < 7 && (
          <Button disabled className="opacity-100">
            {"Step " + inSection}
          </Button>
        )}

        <TransitionGroup className="todo-list">
          <CSSTransition key={inSection} timeout={500} classNames="item">
            {PageList[inSection].element}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
};

export default Promotion;
