import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
    Navbar,
    Container,
    NavItem,
} from "react-bootstrap";

import CanvasBar from './CanvasBar';
import config from "_config";
import "./nav.css";

import {HamburgerMenu} from "utilities/SocialIcons"
import { SearchInventory } from 'Filter/SearchInventory';

const NavBar2 = () => {
    const [showCanvas, setShowCanvas] = useState(false)

    function NavigateClose() {
        setShowCanvas(!showCanvas)
    }

    return (
        <>
            <div>
                <Container fluid>


                    {/* mobile */}
                    <div className="d-md-none">
                        <div className="d-flex justify-content-between">
                             <HamburgerMenu onClick={NavigateClose} style={{marginTop:"10px"}}/>
                                <img
                                    src={require("assets/images/logo/companyLogo.png")}
                                    width={"200px"}
                                    className="img-fluid logo-image"
                                    alt={config.company}
                                    onClick={NavigateClose}
                                />
                            

                            <CanvasBar showCanvas={showCanvas} setShowCanvas={setShowCanvas} />

                        </div>

                    </div>
                    {/* desktop */}
                    <div className="d-none d-md-block d-flex">

                        <div className="d-flex justify-content-between">
                            <Navbar.Brand
                                id="navbar-brand"
                                className="logo-image"
                                href="/"
                                tag={Link}
                            >
                                <img
                                    src={require("assets/images/logo/companyLogo.png")}
                                    height={"80px"}
                                    // className="img-fluid logo-image"
                                    alt={config.company}
                                />
                            </Navbar.Brand>
                            <div className='d-flex justify-content-end'>
                                <NavItem style={{ marginTop: "40px" }}>
                                    <SyledLink to="/inventory">
                                        Inventory
                                    </SyledLink>
                                </NavItem>

                                <NavItem style={{ marginTop: "40px" }}>
                                    <SyledLink to="/sell">
                                        Cash For Cars
                                    </SyledLink>
                                </NavItem>
                                <NavItem style={{ marginTop: "40px" }}>
                                    <SyledLink to="/credit-application">
                                        Finance
                                    </SyledLink>
                                </NavItem>
                                <NavItem style={{ marginTop: "40px" }}>
                                    <SyledLink to="/loan-calculator">
                                        Loan Calculator
                                    </SyledLink>
                                </NavItem>
                                <NavItem style={{ marginTop: "40px" }}>
                                    <SyledLink to="/contact">
                                        Contact
                                    </SyledLink>
                                </NavItem>
                                <SearchInventory
                                    marginTop={"35px"}
                                    setShowCanvas={setShowCanvas} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

        </>
    );
};

export default NavBar2;

const SyledLink = styled(NavLink)`
color: #686868;
text-decoration: none;
padding: 0 10px;
margin:10px 0px 10px 0px ;
`

