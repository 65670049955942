import { put, all, call, takeLatest } from 'redux-saga/effects'
import { 
    GET_INVENTORIES_PENDING,
    GET_INVENTORIES_LOADING,
    GET_INVENTORIES,
    LOAD_INVENTORY,
    SEARCH_INVENTORY,
    SG_SEARCH_INVENTORY,
    GET_MORE_INVENTORY,
    LOAD_MORE_INVENTORY,
    GET_INVENTORY_LIST,
    LOAD_INVENTORY_LIST,
    FETCH_INVENTORY_PENDING,
    FETCH_INVENTORY,
    FETCH_INVENTORY_ONE, 
    GET_PRICED_INVENTORY,
    LOAD_PRICED_INVENTORY,
    GET_MAKE_INVENTORY,
    LOAD_MAKE_INVENTORY,
    GET_YEAR_INVENTORY,
    LOAD_YEAR_INVENTORY,
    GET_MODEL_INVENTORY,
    LOAD_MODEL_INVENTORY,
    SELECT_INVENTORY,
    SG_SELECT_INVENTORY,
    APPLIED_INVENTORY,
    SG_APPLIED_INVENTORY,
    ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';
function* loadInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORIES, action.payload)

    yield put({ type: GET_INVENTORIES, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryLoad() {
  yield takeLatest(LOAD_INVENTORY, loadInventory);
}

function* searchInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORIES, action.payload)

    yield put({ type: SEARCH_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventorySearch() {
  yield takeLatest(SG_SEARCH_INVENTORY, searchInventory);
}

function* loadMoreInventory(action) {
  yield put({ type: GET_INVENTORIES_LOADING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORIES, action.payload)

    yield put({ type: GET_MORE_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryLoadMore() {
  yield takeLatest(LOAD_MORE_INVENTORY, loadMoreInventory);
}

function* loadInventoryList(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORY_LIST, action.payload)

    yield put({ type: GET_INVENTORY_LIST, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryLoadList() {
  yield takeLatest(LOAD_INVENTORY_LIST, loadInventoryList);
}

function* loadPricedInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORY_LIST, action.payload)

    yield put({ type: GET_PRICED_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryPricedLoad() {
  yield takeLatest(LOAD_PRICED_INVENTORY, loadPricedInventory);
}

function* loadMakeInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORY_LIST, action.payload)

    yield put({ type: GET_MAKE_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryMakeLoad() {
  yield takeLatest(LOAD_MAKE_INVENTORY, loadMakeInventory);
}

function* loadModelInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORY_LIST, action.payload)

    yield put({ type: GET_MODEL_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryModelLoad() {
  yield takeLatest(LOAD_MODEL_INVENTORY, loadModelInventory);
}

function* loadYearInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  // console.log('action.payload', action.payload)
  try {
    // const json = yield call(api.getPagedInventories, action.payload)
    const json = yield call(api.GET_INVENTORY_LIST, action.payload)

    yield put({ type: GET_YEAR_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* inventoryYearLoad() {
  yield takeLatest(LOAD_YEAR_INVENTORY, loadYearInventory);
}


  


  function* fetchInventory(action) {
  
    yield put({ type: FETCH_INVENTORY_PENDING });
    try {
      const json = yield call(api.FETCH_INVENTORY, action.payload)
      yield put({ type: FETCH_INVENTORY, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* inventoryFetchOne() {
    yield takeLatest(FETCH_INVENTORY_ONE, fetchInventory);
  }

  function* selectInventory(action) {
    try {
      yield put({ type: SELECT_INVENTORY, payload: action.payload });
    } catch (e) {
      yield put({ type: ALL_ERRORS, message: e });
    }
  }
  
  export function* inventorySelectOne() {
    yield takeLatest(SG_SELECT_INVENTORY, selectInventory);
  }
  
  function* appliedInventory(action) {
    try {
      yield put({ type: APPLIED_INVENTORY, payload: action.payload });
    } catch (e) {
      yield put({ type: ALL_ERRORS, message: e });
    }
  }
  
  export function* inventoryApplied() {
    yield takeLatest(SG_APPLIED_INVENTORY, appliedInventory);
  }


  export default function* index() {
    yield all([
      inventoryPricedLoad(),
      inventoryMakeLoad(),
      inventoryYearLoad(),
      inventoryModelLoad(),
      inventoryLoad(), 
      inventorySearch(),
      inventoryLoadMore(),
      inventoryLoadList(),
      inventoryFetchOne(),
      inventorySelectOne(),
      inventoryApplied(),
    ]);
  }
