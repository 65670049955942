import { GET_CARMODELS, DELETE_CARMODEL, ADD_CARMODEL, 
    FETCH_CARMODEL, EDIT_CARMODEL, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    carmodels: []
};

export default function CarModels (state = initialState, action) {
    switch (action.type) {
        case GET_CARMODELS:
            return {
                ...state,
                carmodels: action.payload
            };
        case FETCH_CARMODEL:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARMODEL:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARMODEL:
            return {
                ...state,
                carmodels: state.carmodels.filter(CarModel => CarModel.id !== action.payload)
            };
        case ADD_CARMODEL:
            return {
                ...state,
                carmodels: [...state.carmodels, action.payload]
            };

        case CLEAR_ALL:
            return {
                ...state,
                carmodels: []
            };
        default:
            return state;
    }
}

