import { GET_CARMAKES, DELETE_CARMAKE, ADD_CARMAKE, 
    FETCH_CARMAKE, EDIT_CARMAKE, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    carmakes: []
};

export default function CarMakes (state = initialState, action) {
    switch (action.type) {
        case GET_CARMAKES:
            return {
                ...state,
                carmakes: action.payload
            };
        case FETCH_CARMAKE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARMAKE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARMAKE:
            return {
                ...state,
                carmakes: state.carmakes.filter(CarMake => CarMake.id !== action.payload)
            };
        case ADD_CARMAKE:
            return {
                ...state,
                carmakes: [...state.carmakes, action.payload]
            };

        case CLEAR_ALL:
            return {
                ...state,
                carmakes: []
            };
        default:
            return state;
    }
}

