import React from "react";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";

import Flip from "react-reveal/Flip";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// reactstrap components
import {
  Container,
  Button,
  Form,
  Input,
  Row,
  Col,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";

import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import { LeftArrow } from "utilities/SocialIcons";

import "./style.css";

let years = [];
_.times(20, (i) => {
  years.push({ value: i, label: `${i}` });
});

let months = [];
_.times(11, (i) => {
  months.push({ value: i, label: `${i}` });
});

const AnnualIncome = ({ setDirection, setInSection, closeApplication }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm();

  const [monthlyIncome, setMonthlyIncome] = React.useState(0);
  const [employment, setEmployment] = React.useState([]);

  const { get_employment } = useSelector(
    (state) => ({
      get_employment: state.application.application,
    }),
    shallowEqual
  );

  console.log(get_employment);

  const { control } = useForm();

  React.useEffect(() => {
    if (_.has(get_employment, "employment")) {
      setEmployment(get_employment.employment);
      setMonthlyIncome(get_employment.employment.monthly_income);
    }
  }, [get_employment]);

  React.useEffect(() => {
    register({ name: "monthly_income" }, { required: false, min: 1 });
  }, [register]);

  React.useEffect(() => {
    setValue("monthly_income", monthlyIncome);
  }, [setValue, monthlyIncome]);

  const onSubmit = (data) => {
    // console.log("Employement Data -----> ",data);
    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        employment: {
          ...data,
        },
      },
    });
    setDirection(true);
    setInSection(5);
  };
  //
  function GoBack() {
    setDirection(false);
    setInSection(3);
  }



  return (
    <>
      <Container>
        <LargeContainer>
          <Button close onClick={closeApplication} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                  <LeftArrow onClick={GoBack} />{" "}
                </span>
                Employement
              </h2>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <FormGroup>
                <Col>
                  Employement
                  <FormGroup check>
                    <Label check>
                      <Controller
                        as={<Input type="radio" />}
                        name="job_type"
                        defaultValue={false}
                        value="Full Time"
                        control={control}
                        innerRef={register()}                       
                      />
                      Full Time <span className="form-check-sign" />
                    </Label>
                    <br />

                    <Label check>
                      <Controller
                        as={<Input type="radio" />}
                        name="job_type"
                        defaultValue={false}
                        value="Part Time"
                        control={control}
                        innerRef={register()}
                      />
                      Part Time <span className="form-check-sign" />
                    </Label>
                    <br />
                    <Label check>
                      <Controller
                        as={<Input type="radio" />}
                        name="job_type"
                        defaultValue={false}
                        value="Self Employeed"
                        control={control}
                        innerRef={register()}
                      />
                      Self Employeed <span className="form-check-sign" />
                    </Label>
                    <br />
                    <Label check>
                      <Controller
                        as={<Input type="radio" />}
                        name="job_type"
                        defaultValue={false}
                        value="Unemployeed"
                        control={control}
                        innerRef={register()}
                      />
                      Unemployeed <span className="form-check-sign" />
                    </Label>
                    <br />
                    <Label check>
                      <Controller
                        as={<Input type="radio" />}
                        name="job_type"
                        defaultValue={false}
                        value="Other (LTD, Child Tax)"
                        control={control}
                        innerRef={register()}
                      />
                      Other (LTD, Child Tax)
                      <span className="form-check-sign" />
                    </Label>
                    <br />
                    <p className="text-danger small">
                      {errors.agreed_to_terms && (
                        <span>{errors.agreed_to_terms.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>
              </FormGroup>
              <Col md="6">
                <FormGroup>
                  <p>
                    How Long On Job
                    {/* <span className="icon-danger">*</span> */}
                  </p>
                  <Input
                    className="border-input"
                    name="job_years"
                    placeholder="Job Years"
                    type="text"
                    innerRef={register()}
                  />
                  <p className="text-danger small">
                    {errors.job_years && (
                      <span>{errors.job_years.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <p>
                  Business Name
                  {/* <span className="icon-danger">*</span> */}
                  </p>
                  <Input
                    className="border-input"
                    name="business_name"
                    placeholder="Business Name"
                    type="text"
                    innerRef={register()}
                  />
                  <p className="text-danger small">
                    {errors.business_name && (
                      <span>{errors.business_name.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <p>
                  Occupation
                  {/* <span className="icon-danger">*</span> */}
                  </p>
                  <Input
                    className="border-input"
                    name="occupation"
                    placeholder="Occupation"
                    type="text"
                    innerRef={register()}
                  />
                  <p className="text-danger small">
                    {errors.occupation && (
                      <span>{errors.occupation.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <p>
                  Pay
                    {/* <span className="icon-danger">*</span> */}
                  </p>
                  <Input
                    name="monthly_income"
                    defaultValue={employment ? employment.monthly_income : ""}
                    className="border-input"
                    placeholder="Montly Income"
                    innerRef={register()}
                    type="number"
                  />
                  <p className="text-danger small">
                    {errors.monthly_income && (
                      <span>{errors.monthly_income.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex justify-content-between">
              <div className="p-2">
                <Button
                  className="btn"
                  color="secondary"
                  onClick={() => GoBack()}
                >
                  Prev
                </Button>
              </div>
              <div className="p-2">
                <Button className="btn" color="danger" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </LargeContainer>
      </Container>
    </>
  );
};

export default AnnualIncome;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  height: 100%;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

// const BouncyDiv = styled.div`
//   padding: 5px;
//   margin: 5px;
//   border-radius: 5px;
//   // cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   align-items: space-between;
//   justify-content: center; // left to right
//   // border-color: #000000;
//   // border-style: solid;
//   // border-width: 1px;
//   // &:hover {
//   //   border-color: #ff0000;
//   //   border-style: solid;
//   //   border-width: 1px;
//   // }
// `;

// const HeaderRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: space-between;
//   justify-content: space-between; // left to right
// `;
// const MainContent = styled.div`
//   padding: 5px;
//   // min-height: 60px;
//   background-color: #ffffff;
//   margin: 5px;
//   font-size: large;
// `;
