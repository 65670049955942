import axios from "axios";
import urlconfig from '../_config';
import store from './store';


const VERSION = 'v1'

const d_token = urlconfig.apikey

// Other urls
export const GET_DEALERS = () => axios.get(`${urlconfig.serverapi}/${VERSION}/dealer/?dealer-code=${d_token}`);

export const GET_CMS = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/cash-for-cars/?${params ? params : ""}`);
export const GET_CMS_CONTACT = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/contact-cms/?${params ? params : ""}`);
export const GET_CMS_PRIVACY = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/privacy-cms/?${params ? params : ""}`);
export const GET_CMS_TERMS = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/terms-cms/?${params ? params : ""}`);
export const GET_CMS_ABOUT = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/aboutpage-cms/?${params ? params : ""}`);
export const GET_CMS_HOME = (params = null) => axios.get(`${urlconfig.serverapi}/${VERSION}/homepage-cms/?${params ? params : ""}`);
export const GET_EMPLOYEE = () => axios.get(`${urlconfig.serverapi}/${VERSION}/employee/?dealer-code=${d_token}`);
export const GET_WEB_COMMENTS = () => axios.get(`${urlconfig.serverapi}/${VERSION}/web-comments/?dealer-code=${d_token}`);

export const GET_CUSTOMERS = (params=null)  =>  axios.get(`${urlconfig.serverapi}/${VERSION}/customer/?dealer-code=${d_token}`);
export const fetchCustomer = (params=null)  =>  axios.get(`${urlconfig.serverapi}/${VERSION}/customer/?dealer-code=${d_token}&${params}`);
export const GET_GARAGE = () =>  axios.get(`${urlconfig.serverapi}/${VERSION}/garage`);
export const GET_SERVICE_REQUESTS = () =>  axios.get(`${urlconfig.serverapi}/${VERSION}/service-request`);
// 
// this is valid with a dealer id which it auto populates
export const GET_CARYEARS = () => axios.get(`${urlconfig.serverapi}/${VERSION}/year/?dealer-code=${d_token}`)
export const GET_CARMAKES = () => axios.get(`${urlconfig.serverapi}/${VERSION}/make/?dealer-code=${d_token}`)
export const GET_CARMODELS = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/model/?dealer-code=${d_token}${params? `&${params}`: ''}`)
export const GET_CARTRIMS = () => axios.get(`${urlconfig.serverapi}/${VERSION}/trim/?dealer-code=${d_token}`)
export const GET_CARCOLORS = () => axios.get(`${urlconfig.serverapi}/${VERSION}/color/?dealer-code=${d_token}`)
export const GET_CARBODYTYPES = () => axios.get(`${urlconfig.serverapi}/${VERSION}/body-type/?dealer-code=${d_token}`)
export const GET_KMS_PRICE = () => axios.get(`${urlconfig.serverapi}/${VERSION}/page-filter?dealer-code=${d_token}`)

// this is for the select list of year make model trim of car
export const GET_CARYEARS_LIST = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/year/?dealer-code=${d_token}&${params}`)
export const GET_CARMAKES_LIST = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/make/?dealer-code=${d_token}&${params}`)
export const GET_CARMODELS_LIST = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/model/?dealer-code=${d_token}&${params}`)
export const GET_CARTRIMS_LIST = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/trim/?dealer-code=${d_token}&${params}`)

export const GET_YEAR_MAKE_MODEL_TRIM = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/year-make-model-trim/?dealer-code=${d_token}${params? `&q=${params}`: ''}`)

export const GET_YEAR_MAKE = (params) =>{
// console.log("API Param check", params)
 return axios.get(`${urlconfig.serverapi}/${VERSION}/year-make/?dealer-code=${d_token}${params? `&q=${params}`: ''}`)
}
// Post All Leads / Appointments / Tradeins
export const POST_WEB_LEADS = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/web-leads/?dealer-code=${d_token}`, payload)
export const POST_EMPLOYEE_TASKS = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/employee-tasks/?dealer-code=${d_token}`, payload)
export const POST_APPOINTMENT = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/customer-appointment/?dealer-code=${d_token}`, payload)
export const POST_TRADEIN = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/customer-tradein/?dealer-code=${d_token}`, payload)
export const POST_CREDIT_APPLICATION = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/credit-application/?dealer-code=${d_token}`, payload)
export const POST_WEB_COMMENTS = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/web-comments/?dealer-code=${d_token}`, payload)
export const POST_CONTACTUS = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/contact-us/?dealer-code=${d_token}`, payload)
export const POST_LEASEBUSTER = (payload) => axios.post(`${urlconfig.serverapi}/${VERSION}/lease-buster/?dealer-code=${d_token}`, payload)

// 

//  http://127.0.0.1:8000/api/web/v1/year-make-model-trim?dealer-code=2607e4bf-3af2-4c6f-a70b-304a1b205c81&q=2019
export const GET_INVENTORIES = (payload) => {
    const {pageparams, pageactivePage} = payload
    return axios.get(`${urlconfig.serverapi}/${VERSION}/inventory/?dealer-code=${d_token}&page=${pageactivePage? pageactivePage: 1}${pageparams? pageparams: ""}`)
}

// inventory-list/
export const GET_INVENTORIES_SELECT_LIST = (params) => axios.get(`${urlconfig.serverapi}/${VERSION}/inventory-select-list/?dealer-code=${d_token}${params?`&search=${params}`: ''}`)

export const GET_INVENTORY_LIST = (payload) => {
    // console.log(payload)
    return axios.get(`${urlconfig.serverapi}/${VERSION}/inventory-list/?dealer-code=${d_token}${payload? payload: ""}`)  
}




export const POST_WEB_VISIT = (payload) => {
    return axios.post(`${urlconfig.serverapi}/${VERSION}/web-visits/?dealer-code=${d_token}`, payload)  
}

export const FETCH_INVENTORY = (inventory_id) => axios.get(`${urlconfig.serverapi}/${VERSION}/inventory/${inventory_id}/?dealer-code=${d_token}`,)

export const ADD_LEAD = "ADD_LEAD";
export const UPDATE_LEADS = 'UPDATE_LEADS';
export const CLEAR_LEADS = 'CLEAR_LEADS';

export const GET_LANDINGPAGE = (payload) => {
    // console.log(payload)
 return axios.get(`${urlconfig.serverapi}/${VERSION}/landingpage/?dealer-code=${d_token}&slug=${payload}`)
}

export const FETCH_CUSTOMER_MESSAGE = (message_id) => axios.get(`${urlconfig.serverapi}/${VERSION}/customer-message/${message_id}/?dealer-code=${d_token}`,)

// blackbook api
export const GET_BLACKBOOK_API = (payload) => {
    // console.log(payload)
 return axios.get(`${urlconfig.serverapi}/${VERSION}/black-book-api/?dealer-code=${d_token}&${payload}`)
}

export const GET_WEB_LEAD_SOURCE = (payload) => {
    // console.log(payload)
    return axios.get(`${urlconfig.serverapi}/${VERSION}/web-lead-source/?dealer-code=${d_token}${payload? `&${payload}`: ""}`)  
}
// // multiple
// axios.all([
//     axios.get('https://api.github.com/users/iliakan'), 
//     axios.get('https://api.github.com/users/taylorotwell')
//   ])
//   .then(axios.spread((obj1, obj2) => {
//     // Both requests are now complete
//     console.log(obj1.data.login + ' has ' + obj1.data.public_repos + ' public repos on GitHub');
//     console.log(obj2.data.login + ' has ' + obj2.data.public_repos + ' public repos on GitHub');
//   }));

// Login Urls
// CHECK TOKEN & LOAD USER
export const LOAD_USER = () => (getState) => {
    return axios.get(`${urlconfig.loginapi}/${VERSION}/profile/`, tokenConfig(getState))
       
};



export const LOGIN_USER = (body) => {
    // const body = JSON.stringify({ email, password, dealer_token });
    const response = axios.post(`${urlconfig.loginapi}/${VERSION}/customer-portal-token/`, body, config)
   return response
};

export const REGISTER_USER = ({ password, password2, first_name, last_name, email, dealer_token }) => {
    // Request Body
    const body = JSON.stringify({ email, password, password2, first_name, last_name, dealer_token, });
    return axios.post(`${urlconfig.loginapi}/${VERSION}/create/customer`, body, config)
};

export const LOGOUT_USER = () => (getState) => {
    return (`${urlconfig.authapi}/${VERSION}/logout/`, null, tokenConfig(getState))
};

export const FORGOT_PASSWORD = ({  email, dealer_token, token_type, user_ip })  => {
    // Request Body
    const body = JSON.stringify({ email, dealer_token, token_type, user_ip});
    return axios.post(`${urlconfig.loginapi}/${VERSION}/customer/password_reset/`, body, config)
};

export const RESET_PASSWORD = ({  password, token }) => {
    // Request Body
    const body = JSON.stringify({ password, token, });
   return (`${urlconfig.loginapi}/${VERSION}/customer/password_reset/confirm/?token=${token}`, body, config)
};

// Headers
const config = {
    headers: {
        "Content-Type": "application/json"
    }
};

const tokenConfig = () => {
    const state = store.getState();
    // Get token from state
    const token = state.auth.token;
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
};