import { put, all, call, takeLatest } from 'redux-saga/effects'
import { LOAD_CARYEARS, GET_CARYEARS, LOAD_CARMAKES, 
     GET_CARMAKES, LOAD_CARMODELS,
     GET_CARMODELS, LOAD_CARTRIMS, 
     GET_CARCOLORS, GET_CARBODYTYPES,
     LOAD_CARCOLORS,LOAD_CARBODYTYPES,
     LOAD_KM_PRICES, GET_KM_PRICES,
    //  CLEAR_KM_PRICE,
    GET_YEAR_MAKE_PENDING,
    SG_LOAD_YEAR_MAKE,
    BB_GET_YEAR_MAKE,
    FETCH_YEAR_MAKE,
    SG_FETCH_YEAR_MAKE,
     GET_CARTRIMS, 
     LOAD_YEAR_MAKE_MODEL_TRIM,
     GET_YEAR_MAKE_MODEL_TRIM,
     FETCH_YEAR_MAKE_MODEL_TRIM,
     SG_FETCH_YEAR_MAKE_MODEL_TRIM,
     SUBMIT_YEAR_MAKE_MODEL_TRIM,
     SG_SUBMIT_YEAR_MAKE_MODEL_TRIM,
     GET_YEAR_MAKE_MODEL_TRIM_PENDING,
     ALL_ERRORS } from "constants/actions";
import * as api from 'constants/api';


function* loadCarYears(action) {
    
    try {
        const json = yield call(api.GET_CARYEARS, action.payload)
        yield put({ type: GET_CARYEARS, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadCarMakes(action) {
    try {
        const json = yield call(api.GET_CARMAKES, action.payload)
        yield put({ type: GET_CARMAKES, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadCarModels(action) {
    // console.log(action.payload)
    try {
        const json = yield call(api.GET_CARMODELS, action.payload)
        yield put({ type: GET_CARMODELS, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadCarTrims(action) {
    try {
        const json = yield call(api.GET_CARTRIMS, action.payload)
        yield put({ type: GET_CARTRIMS, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadCarColors(action) {
    // console.log("Load car colors")
    try {
        const json = yield call(api.GET_CARCOLORS, action.payload)
        yield put({ type: GET_CARCOLORS, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadCarBodyTypes(action) {
    try {
        const json = yield call(api.GET_CARBODYTYPES, action.payload)
        yield put({ type: GET_CARBODYTYPES, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadKilometerPrices(action) {
    try {
        const json = yield call(api.GET_KMS_PRICE, action.payload)
        yield put({ type: GET_KM_PRICES, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* loadYearMakeModelTrim(action) {
    yield put({type: GET_YEAR_MAKE_MODEL_TRIM_PENDING})
    // console.log(action.payload)
    try {
        const json = yield call(api.GET_YEAR_MAKE_MODEL_TRIM, action.payload)
        yield put({ type: GET_YEAR_MAKE_MODEL_TRIM, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

function* fetchYearMakeModelTrim(action) {
    // console.log(action.payload)
    try {
      yield put({ type: FETCH_YEAR_MAKE_MODEL_TRIM, payload: action.payload });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

  function* submitYearMakeModelTrim(action) {
    try {
      yield put({ type: SUBMIT_YEAR_MAKE_MODEL_TRIM, payload: action.payload });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }

 

export function* carYearLoad() {
    yield takeLatest(LOAD_CARYEARS, loadCarYears);
}

export function* carMakeLoad() {
    yield takeLatest(LOAD_CARMAKES, loadCarMakes);
}

export function* carModelLoad() {
    yield takeLatest(LOAD_CARMODELS, loadCarModels);
}

export function* carTrimLoad() {
    yield takeLatest(LOAD_CARTRIMS, loadCarTrims);
}

export function* carColorLoad() {
    yield takeLatest(LOAD_CARCOLORS, loadCarColors);
}

export function* carBodyTypeLoad() {
    yield takeLatest(LOAD_CARBODYTYPES, loadCarBodyTypes);
}

export function* carKilometerPriceLoad() {
    yield takeLatest(LOAD_KM_PRICES, loadKilometerPrices);
}
// 

export function* YearMakeModelTrimLoad() {
    yield takeLatest(LOAD_YEAR_MAKE_MODEL_TRIM, loadYearMakeModelTrim);
}

export function* yearMakeModelTrimFetchOne() {
    yield takeLatest(SG_FETCH_YEAR_MAKE_MODEL_TRIM, fetchYearMakeModelTrim);
  }

  export function* yearMakeModelTrimSubmit() {
    yield takeLatest(SG_SUBMIT_YEAR_MAKE_MODEL_TRIM, submitYearMakeModelTrim);
  }


function* selectCarYearMakes(action) {
    yield put({ type: GET_YEAR_MAKE_PENDING});
    try {
        const json = yield call(api.GET_YEAR_MAKE, action.payload)
        yield put({ type: BB_GET_YEAR_MAKE, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* YearMakeSelect() {
    yield takeLatest(SG_LOAD_YEAR_MAKE, selectCarYearMakes);
}

function* fetchYearMakeSelect(action) {
    yield put({ type: GET_YEAR_MAKE_PENDING});
    try {
        const json = yield call(api.GET_CARYEARS_LIST, action.payload)
        yield put({ type: FETCH_YEAR_MAKE, payload: json.data, });
    } catch (e) {
        yield put({type: ALL_ERRORS, message: e});
    }
}

export function* yearMakeSelectFetchOne() {
    yield takeLatest(SG_FETCH_YEAR_MAKE, fetchYearMakeSelect);
}

export default function* index() {
    yield all([
        carYearLoad(), 
        carMakeLoad(), 
        carModelLoad(), 
        carTrimLoad(),
        carColorLoad(), 
        carBodyTypeLoad(),
        carKilometerPriceLoad(),
        YearMakeModelTrimLoad(), 
        yearMakeModelTrimFetchOne(),
        yearMakeModelTrimSubmit(),
        YearMakeSelect(),
        yearMakeSelectFetchOne(),  
    ])
  }
