import { 
    GET_MAKE_SELECT, 
    FETCH_MAKE_SELECT, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    makeselect: [],
    makeselected: []
};

export default function SelectMake (state = initialState, action) {
    switch (action.type) {
        case GET_MAKE_SELECT:
            return {
                ...state,
                makeselect: action.payload
            };
        case FETCH_MAKE_SELECT:
            return { ...state, makeselected: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                makeselect: []
            };
        default:
            return state;
    }
}

