import { put, call, all, takeLatest } from 'redux-saga/effects'
import { GET_DEALERS, DEALER_WEBSITE_REQUESTED,
  GET_EMPLOYEES, LOAD_EMPLOYEES,
  GET_WEB_COMMENTS, LOAD_WEB_COMMENTS,
   ALL_ERRORS } from "constants/actions";
import * as api from 'constants/api';

function* loadDealer(action) {
    try {
    const json = yield call(api.GET_DEALERS, action.payload)       
    yield put({ type: GET_DEALERS, payload: json.data[0], });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
}
  
export function* websiteLoad() {

   yield takeLatest(DEALER_WEBSITE_REQUESTED, loadDealer);

}

function* loadEmployees(action) {

  try {
    const json = yield call(api.GET_EMPLOYEE, action.payload);
    yield put({ type: GET_EMPLOYEES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* EmployeesLoad() {
  yield takeLatest(LOAD_EMPLOYEES, loadEmployees);
}

function* loadComment(action) {
  try {
    const json = yield call(api.GET_WEB_COMMENTS, action.payload);
    yield put({ type: GET_WEB_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* CommentsLoad() {
  yield takeLatest(LOAD_WEB_COMMENTS, loadComment);
}

export default function* index() {
  yield all([
    websiteLoad(),
    EmployeesLoad(),
    CommentsLoad(),
  ]);
}