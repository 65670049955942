import { put, call, all, takeLatest } from 'redux-saga/effects'
import { GET_DEALERS, DEALER_WEBSITE_REQUESTED,LOAD_CMS, GET_CMS,GET_CMS_CONTACT,LOAD_CMS_CONTACT,GET_CMS_PRIVACY, LOAD_CMS_PRIVACY,GET_CMS_TERMS,LOAD_CMS_TERMS,GET_CMS_ABOUT,LOAD_CMS_ABOUT,GET_CMS_HOME,LOAD_CMS_HOME,
  GET_EMPLOYEES, LOAD_EMPLOYEES,
  GET_WEB_COMMENTS, LOAD_WEB_COMMENTS,
   ALL_ERRORS } from "constants/actions";
import * as api from 'constants/api';


function* loadCMS(action) {
  try {
  const json = yield call(api.GET_CMS, action.payload)       
  yield put({ type: GET_CMS, payload: json.data });
}
catch (e) {
  yield put({type: ALL_ERRORS, error: e.message});
  }
}

export function* cmsLoad() {
 yield takeLatest(LOAD_CMS, loadCMS);
}

function* loadCMSContact(action) {
    try {
    const json = yield call(api.GET_CMS_CONTACT, action.payload)       
    yield put({ type: GET_CMS_CONTACT, payload: json.data });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
  }
  
  export function* ContactCmsLoad() {  
   yield takeLatest(LOAD_CMS_CONTACT, loadCMSContact);  
  }

  function* loadCMSPrivacy(action) {
    try {
    const json = yield call(api.GET_CMS_PRIVACY, action.payload)       
    yield put({ type: GET_CMS_PRIVACY, payload: json.data });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
  }
  
  export function* privacyCmsLoad() {  
   yield takeLatest(LOAD_CMS_PRIVACY, loadCMSPrivacy);  
  }

  function* loadCMSTerms(action) {
    try {
    const json = yield call(api.GET_CMS_TERMS, action.payload)       
    yield put({ type: GET_CMS_TERMS, payload: json.data });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
  }
  
  export function* termsCmsLoad() {  
   yield takeLatest(LOAD_CMS_TERMS, loadCMSTerms);  
  }

  function* loadCMSAbout(action) {
    try {
    const json = yield call(api.GET_CMS_ABOUT, action.payload)       
    yield put({ type: GET_CMS_ABOUT, payload: json.data });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
  }
  
  export function* aboutCmsLoad() {  
   yield takeLatest(LOAD_CMS_ABOUT, loadCMSAbout);  
  }

  function* loadCMSHome(action) {
    try {
    const json = yield call(api.GET_CMS_HOME, action.payload)       
    yield put({ type: GET_CMS_HOME, payload: json.data });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
  }
  
  export function* homeCmsLoad() {  
   yield takeLatest(LOAD_CMS_HOME, loadCMSHome);  
  }

export default function* index() {
  yield all([
    cmsLoad(),
    ContactCmsLoad(),
    privacyCmsLoad(),
    termsCmsLoad(),
    aboutCmsLoad(),
    homeCmsLoad(),
  ]);
}