
import { GET_IMAGEPROFILES, FETCH_IMAGEPROFILE, 
    DELETE_IMAGEPROFILE, ADD_IMAGEPROFILE, 
    EDIT_IMAGEPROFILE, CLEAR_ALL } from "constants/actions";


export default function ImageProfile (state = {}, action) { 
    switch (action.type) {
        case GET_IMAGEPROFILES:
            return {
                ...state,
                imageprofiles: action.payload
            };

        case FETCH_IMAGEPROFILE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_IMAGEPROFILE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_IMAGEPROFILE:
            return {
                ...state,
                imageprofiles: state.imageprofiles.filter(Imageprofile => Imageprofile.id !== action.payload)
            };
        case ADD_IMAGEPROFILE:
            // console.log('Post Inventory ID', action.payload)
            return {
                ...state,
                imageprofiles: [...state.imageprofiles, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                imageprofiles: []
            };
        default:
            return state;
    }
}


