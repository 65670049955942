import React from "react";
import styled, { keyframes } from 'styled-components';
// react library for routing
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

import { fadeInLeft, bounce } from 'react-animations'

import Car from "assets/images/vehicles/noun_Car sedan_1614723.png";
import Truck from "assets/images/vehicles/noun_Pickup truck small_1628341.png";
import Van from "assets/images/vehicles/noun_Station wagon Small_1628337.png";
import SUV from "assets/images/vehicles/noun_suv vehicle_1614711.png";


const BouncyDiv = styled.div`
padding: 5px;
border-radius: 15px;
  cursor: pointer;
  &:hover {
    animation: 2s ${keyframes`${bounce}`} infinite;
    background-color: #dcdcdc;
  }
`;
const PromoHeader = ({setInSection}) => {
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        vehicleType: e,
      },
    });
    // MakeContactVisible(2) 
    setInSection(2)
  };


  function SetClicked(e) {
   
  }

  const { get_application } = useSelector(
    (state) => ({
      get_application: state.application.application,
    }),
    shallowEqual
  );

  return (
    <>
             <Container fluid>
                  <Container className="mb-5">   
            <Row className="justify-content-center text-center">
              <Col md="8">
                <h2 className="display-4">What are you looking for?</h2>
              </Col>
            </Row>
        

        <div className="fadeInLeft">
          <Row className="align-items-center">
            <Col lg="12">
              <Row className="pt-5">
                <Col md="3">
                  <BouncyDiv 
                  onClick={() => onSubmit('Car')}
                  >
          
                      <h5 className="h4 text-center">Car</h5>
                      <img
                        alt="Created by Juan Pablo Bravo from the Noun Project"
                        className="img-fluid"
                        src={Car}
                      />
          
                  </BouncyDiv>
                </Col>
                <Col md="3">
                <BouncyDiv onClick={() => onSubmit('Van')} >
                      <h5 className="h4 text-center">Van</h5>
                      <img
                        alt="Created by Juan Pablo Bravo from the Noun Project"
                        className="img-fluid"
                        src={Van}
                      />
                    </BouncyDiv>
                </Col>
                <Col md="3">
                <BouncyDiv onClick={() => onSubmit('SUV')} >
                      <h5 className="h4 text-center">SUV</h5>
                      <img
                        alt="Created by Juan Pablo Bravo from the Noun Project"
                        className="img-fluid"
                        src={SUV}
                      />
                  </BouncyDiv>
                </Col>
                <Col md="3">
                <BouncyDiv onClick={() => onSubmit('Truck')} >
                      <h5 className="h4 text-center">Truck</h5>
                      <img
                        alt="Created by Juan Pablo Bravo from the Noun Project"
                        className="img-fluid"
                        src={Truck}
                      />
                  </BouncyDiv>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        </Container>
      </Container>
  
    </>
  );
};

export default PromoHeader;
