import { GET_WEBHEADERS, FETCH_WEBHEADER, DELETE_WEBHEADER, 
    ADD_WEBHEADER, EDIT_WEBHEADER, CLEAR_WEBHEADER, 
    CLEAR_ALL } from "constants/actions";

const initialState = {
    webheaders: []
};

export default function WebHeader (state = initialState, action) {
    switch (action.type) {
        case GET_WEBHEADERS:
            return {
                ...state,
                webheaders: action.payload
            };
        case FETCH_WEBHEADER:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_WEBHEADER:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_WEBHEADER:
            return {
                ...state,
                webheaders: state.webheaders.filter(webheader => webheader.id !== action.payload)
            };
        case ADD_WEBHEADER:
            return {
                ...state,
                webheaders: [...state.webheaders, action.payload]
            };
        case CLEAR_WEBHEADER:
            return {
                ...state,
                webheaders: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                webheaders: []
            };
        default:
            return state;
    }
}
