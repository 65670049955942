import {
  GET_DEALERS,
  DELETE_DEALER,
  ADD_DEALER,
  CLEAR_DEALER,
  CLEAR_ALL,
  FETCH_DEALER,
  GET_CMS,
  GET_CMS_CONTACT,
  GET_CMS_PRIVACY,
  GET_CMS_TERMS,
  GET_CMS_ABOUT,
  GET_CMS_HOME,
  EDIT_DEALER,
} from "constants/actions";

const initialState = {
  cashforcars: [],
  contact: [],
  privacy: [],
  terms: [],
  about: [],
  home: [],
};

export default function Dealer(state = initialState, action) {
  switch (action.type) {
    case GET_CMS:
      return {
        ...state,
        cashforcars: action.payload,
      };

    case GET_CMS_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };

    case GET_CMS_PRIVACY:
      return {
        ...state,
        privacy: action.payload,
      };

    case GET_CMS_TERMS:
      return {
        ...state,
        terms: action.payload,
      };

    case GET_CMS_ABOUT:
      return {
        ...state,
        about: action.payload,
      };

    case GET_CMS_HOME:
      return {
        ...state,
        home: action.payload,
      };

    default:
      return state;
  }
}
