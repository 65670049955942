import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Styled from "styled-components"

import { Container, Alert, Button } from 'react-bootstrap';

import Footer from 'layout/HomeLayout/Footer';
import NavBar from 'layout/HomeLayout/NavBar';


function ReloadPage() {
    const navigate = useNavigate()
    const [show, setShow] = useState(true);

    function refreshPage() {
        navigate("/")
        window.location.reload(false);
        setShow(false)
    }
    if (show) {
        return (
            <>
                <NavBar />
                <Container>
                    <div style={{ marginTop: "100px" }}>
                        <Alert variant="danger" onClose={refreshPage} dismissible>
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <p>
                                Something just broke, please click reload to take you Back!
                            </p>
                        </Alert>
                        <CenterBox>
                            <Button variant="success" size="lg" onClick={refreshPage}>Reload</Button>
                        </CenterBox>
                    </div>
                </Container>
                <Footer />
            
            </>
        );
    }

}

export default ReloadPage;

const CenterBox = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`