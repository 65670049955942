import { GET_INVENTORYIMAGES, DELETE_INVENTORYIMAGE, 
    ADD_INVENTORYIMAGE, CLEAR_ALL, FETCH_INVENTORYIMAGE, 
    EDIT_INVENTORYIMAGE } from "constants/actions";



export default function InventoryImage (state = {}, action) { 
    switch (action.type) {
        case GET_INVENTORYIMAGES:
            return {
                ...state,
                inventoryimages: action.payload
            };

        case FETCH_INVENTORYIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_INVENTORYIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_INVENTORYIMAGE:
            return {
                ...state,
                inventoryimages: state.inventoryimages.filter(Inventoryimage => Inventoryimage.id !== action.payload)
            };
        case ADD_INVENTORYIMAGE:
            console.log('Post Inventory ID', action.payload)
            return {
                ...state,
                inventoryimages: [...state.inventoryimages, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventoryimages: []
            };
        default:
            return state;
    }
}


