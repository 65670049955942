import {
  GET_YEAR_MAKE_PENDING,
  BB_GET_YEAR_MAKE,
  FETCH_YEAR_MAKE,
  SUBMIT_YEAR_MAKE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  yearmakes: [],
  selected: false,
  loading: false,
  submited: [],
};

export default function YearMake(state = initialState, action) {
  switch (action.type) {
    case GET_YEAR_MAKE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case BB_GET_YEAR_MAKE:
      return {
        ...state,
        yearmakes: action.payload,
        loading: false,
      };
    case FETCH_YEAR_MAKE:
      return {
        ...state,
        loading: false,
        selected: action.payload
      };

    case SUBMIT_YEAR_MAKE:
      return {
        ...state,
        submited: [...state.submited, action.payload],
      };

    case CLEAR_ALL:
      return {
        ...state,
        yearmakes: [],
        selected: false,
        loading: false,
        submited: [],
      };
    default:
      return state;
  }
}
