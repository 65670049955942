import { put, all,  takeLatest } from 'redux-saga/effects'

import { GET_LEAD_SOURCE, 
    LOAD_LEAD_SOURCE, UPDATE_LEAD_SOURCE, 
    SG_UPDATE_LEAD_SOURCE,  } from "constants/actions";


function* getLeadSource(action) {
    yield put({ type: GET_LEAD_SOURCE, payload: action.payload });
}
  
export function* getLeadSourceLoad() {
   yield takeLatest(LOAD_LEAD_SOURCE, getLeadSource);
}

function* updateLeadSource(action) {
    yield put({ type: UPDATE_LEAD_SOURCE, payload: action.payload, });
}
  
export function* updateLeadSourceLoad() {
   yield takeLatest(SG_UPDATE_LEAD_SOURCE, updateLeadSource);
}

export default function* index() {
    yield all([getLeadSourceLoad(), updateLeadSourceLoad()]);
  }

