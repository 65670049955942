import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    CREATE_WEB_VISIT,
    // ADD_WEB_VISIT,
    ALL_ERRORS
  } from "constants/actions";

  function* leadWebVisit(action) {
    try {
      yield call(api.POST_WEB_VISIT, action.payload);
    //   yield put({ type: ADD_WEB_VISIT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, message: e });
    }
  }
  
  export function* WebVisitLoad() {
    yield takeLatest(CREATE_WEB_VISIT, leadWebVisit);
  }
  
  export default function* index() {
    yield all([
        WebVisitLoad(),
    ]);
  }