import { GET_ERRORS } from "constants/actions";

const initialState = {
    msg: {},
    status: null
};

export default function Errors (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            console.log('reducer error payload', action.payload)
            return {
                msg: action.payload.msg,
                status: action.payload.status
            };
        default:
            return state;
    }
}