import React from 'react';
import Styled from 'styled-components';
import {  useNavigate } from "react-router-dom";
import {
    Offcanvas,
} from "react-bootstrap";
import {SearchInventory} from 'Filter/SearchInventory';
const CanvasBar = ({ showCanvas, setShowCanvas }) => {
    const navigate = useNavigate()
    function NavigateClose(location) {
        setShowCanvas(false)
        navigate(location)
    }
    return (
        <>
            <Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
                show={showCanvas}
            >
                <Offcanvas.Header  closeButton onClick={() => setShowCanvas(false)}>
                    <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
              
                    <GroupedList>
                        <GroupedItem onClick={() => NavigateClose("/inventory")} >Inventory</GroupedItem>
                        <GroupedItem onClick={() => NavigateClose("/sell")}>Cash For Cars</GroupedItem>
                        <GroupedItem onClick={() => NavigateClose("/credit-application")}>Finance</GroupedItem>
                        <GroupedItem onClick={() => NavigateClose("/loan-calculator")}>Loan Calculator</GroupedItem>
                        <GroupedItem onClick={() => NavigateClose("/contact")}>Contact</GroupedItem>
                    </GroupedList>
                    <SearchInventory setShowCanvas={setShowCanvas} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default CanvasBar;

const GroupedList = Styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
`
const GroupedItem = Styled.div`
position: relative;
display: block;
padding: 0.5rem 1rem;
color: #212529;
text-decoration: none;
cursor: pointer;
background-color: #fff;

&:hover{
    background-color: #dcdcdc;
}
// border: 1px solid rgba(0,0,0,.125);
`