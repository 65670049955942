import { GET_LEAD_SOURCE, 
     UPDATE_LEAD_SOURCE, 
     CLEAR_ALL } from "constants/actions";

const initialState = {
    leadsource: []
};

export default function LeadSource (state = initialState, action) {
    switch (action.type) {
        case GET_LEAD_SOURCE:
            return {  ...state,
                leadsource: action.payload};
    //    case ADD_LEAD:
    //         return {
    //             ...state,
    //             leads: [...state.leads, action.payload]
    //         };
        case UPDATE_LEAD_SOURCE:
            return {  ...state,
                leadsource: action.payload};
        case CLEAR_ALL:
            return {
                ...state,
                activePage: []
            };
        default:
            return state;
    }
}