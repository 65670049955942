import { GET_CARTRIMS, DELETE_CARTRIM, ADD_CARTRIM, 
    FETCH_CARTRIM, EDIT_CARTRIM, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    cartrims: []
};

export default function CarTrims (state = initialState, action) {
    switch (action.type) {
        case GET_CARTRIMS:
            return {
                ...state,
                cartrims: action.payload
            };
        case FETCH_CARTRIM:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARTRIM:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARTRIM:
            return {
                ...state,
                cartrims: state.cartrims.filter(CarTrim => CarTrim.id !== action.payload)
            };
        case ADD_CARTRIM:
            return {
                ...state,
                cartrims: [...state.cartrims, action.payload]
            };

        case CLEAR_ALL:
            return {
                ...state,
                cartrims: []
            };
        default:
            return state;
    }
}

