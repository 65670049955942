import { 
    GET_YEAR_SELECT, 
    FETCH_YEAR_SELECT, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    yearselect: [],
    yearselected: ''

};

export default function SelectYear (state = initialState, action) {
    switch (action.type) {
        case GET_YEAR_SELECT:
            return {
                ...state,
                yearselect: action.payload
            };
        case FETCH_YEAR_SELECT:
            return { ...state, yearselected: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                yearselect: []
            };
        default:
            return state;
    }
}

