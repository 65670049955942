import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./reducers/index";
import { rootSaga } from './sagas/index'

import { composeWithDevTools } from 'redux-devtools-extension';

// const initialState = {};
const sagaMiddleware = createSagaMiddleware()
// const enhancers = [initialState, applyMiddleware(sagaMiddleware)];
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
// console.log(typeof rootReducer)

export const devStore = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const prodStore = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
  );

sagaMiddleware.run(rootSaga)

export default prodStore;