import { GET_WEBSITEIMAGES, FETCH_WEBSITEIMAGE, DELETE_WEBSITEIMAGE, 
    ADD_WEBSITEIMAGE, EDIT_WEBSITEIMAGE, 
    CLEAR_WEBSITEIMAGE, 
    CLEAR_ALL } 
    from "constants/actions";

const initialState = {
    websiteimages: []
};

export default function WebsiteImages (state = initialState, action) {
    switch (action.type) {
        case GET_WEBSITEIMAGES:
            return {
                ...state,
                websiteimages: action.payload
            };
        case FETCH_WEBSITEIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_WEBSITEIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_WEBSITEIMAGE:
            return {
                ...state,
                websiteimages: state.websiteimages.filter(websiteimage => websiteimage.id !== action.payload)
            };
        case ADD_WEBSITEIMAGE:
            return {
                ...state,
                websiteimages: [...state.websiteimages, action.payload]
            };
        case CLEAR_WEBSITEIMAGE:
            return {
                ...state,
                websiteimages: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                websiteimages: []
            };
        default:
            return state;
    }
}
